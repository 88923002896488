/**
 * @ComponentFor PageNotFoundViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import { translate } from '@avensia/scope';
import PageNotFoundViewModel from './PageNotFoundViewModel.type';

type PropType = PageNotFoundViewModel;

const Center = styled(Section, {
  textAlign: 'center',
});

export default (props: PropType) => {
  const { errorPageHeading, errorPageMessage } = props;
  const heading = errorPageHeading ? errorPageHeading : translate('/Errors/PageNotFoundHeader');
  const message = errorPageMessage ? errorPageMessage : translate('/Errors/PageNotFoundText');

  return (
    <Main appearance={PageAppearance.Narrow}>
      <Center>
        <h1>{heading}</h1>
        <p>{message}</p>
      </Center>
    </Main>
  );
};
